import React from 'react'
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';
import AutomotivePic from "../../Images/automotive-pic.jpg";
import AutomotiveImg from "../../Images/automotive.jpg";

const Automotive = () => {
  const content = [
    "dbaffinityrevolutionizes the automotive industry by leveraging advanced technology to drive operational efficiency and elevate customer engagement. Through robust data management solutions, automotive businesses can streamline processes such as tracking customer preferences, optimizing supply chain workflows, and enhancing vehicle maintenance services. These capabilities empower manufacturers and dealerships to achieve greater productivity and efficiency in their daily operations, ensuring a competitive edge in the market.",
    
    "By integrating dbaffinity's  transformative solutions, automotive companies gain real-time insights into market trends and consumer behavior. This enables them to personalize customer experiences, develop innovative products, and respond swiftly to evolving industry demands. The ability to make data-driven decisions positions businesses to thrive in a dynamic landscape where agility and adaptability are critical to success.",
    
    "From connected vehicles to predictive maintenance and advanced logistics, dbaffinityequips the automotive sector with tools to embrace a smarter, more sustainable future. By fostering innovation and sustainability, dbaffinityhelps businesses not only meet current challenges but also anticipate and prepare for future opportunities. With dbaffinityas a partner, the automotive industry can accelerate its journey toward technological excellence and environmental responsibility."
  ];
  
  return (
    <>
      <HeroSection
        backgroundImage={AutomotiveImg}
        title="Automotive"
      />
      <WhoWeAre title="Automotive Industry" content={content} imageSrc={AutomotivePic} />

          </>
  );
}

export default Automotive
