import React from 'react'
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';
import integrtnImg from "../../Images/integrtn.jpg";
import oracle from "../../Images/oracle-integrtn.jpg";

const Middleware = () => {

  const content = [
    
"dbaffinitystands out as a leader in providing end-to-end solutions using Oracle Middleware and Integration services, empowering businesses to achieve smooth and efficient integration across their applications and systems. Their expertise in Oracle's powerful middleware suite—comprising Oracle Integration Cloud, Oracle SOA Suite, and Oracle Cloud Native Services—enables organizations to streamline their IT ecosystems and automate workflows. dbaffinity's  solutions simplify the integration of on-premises and cloud-based applications, ensuring businesses can access real-time data exchange, reduce manual interventions, and improve overall operational efficiency. With a deep understanding of Oracle's capabilities, dbaffinitycustomizes integration strategies that align with the unique needs of each client, driving scalability, reliability, and performance.",

"Leveraging Oracle’s state-of-the-art technology, dbaffinityprovides seamless connectivity between complex applications and systems, ensuring smooth communication across all platforms. Their approach to middleware integration is designed to address specific challenges in various industries, helping clients improve data consistency, minimize errors, and enable smarter decision-making. By enhancing collaboration between systems and departments, dbaffinity's  integration solutions break down silos, allowing businesses to harness the full potential of their existing infrastructure while migrating to a cloud-first model. Clients benefit from real-time visibility and optimized processes, gaining a competitive edge in today’s dynamic market environment."
  ];
  return (
    <>
      <HeroSection
        backgroundImage={integrtnImg}
        title="Oracle Middleware and Integration"
      />
      <WhoWeAre title="Middleware and Integration" content={content} imageSrc={oracle} />

          </>
  );
}

export default Middleware
