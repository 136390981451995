import React from "react";
import HeroSection from "../../Common/HeroSection";
import sqlImage from "../../Images/sqlImage.jpg";
import WhoWeAre from "../../Common/WhoWeAre";
import server from "../../Images/server.jpg";


const Sql = () => {
  const content = [
    "SQL Server Business Intelligence equips businesses with powerful tools to transform raw data into actionable insights, enabling informed and impactful decision-making. By leveraging these tools, organizations can convert basic data into valuable information that drives strategic business outcomes. The Business Intelligence suite supports developers in performing Extract, Transform, and Load (ETL) operations seamlessly, optimizing data processing workflows. It integrates with Data Warehouses to ensure efficient storage, management, and analysis of large datasets for business growth. These capabilities empower businesses to harness the true potential of their data in competitive markets.",
    
    "At dbaffinity, we specialize in helping organizations build and streamline robust Business Intelligence platforms using SQL Server. Our expertise ensures your team can fully utilize BI tools to extract insights and optimize processes effectively. We provide tailored solutions to enhance your data analysis capabilities, improving operational efficiency and decision-making. Whether setting up data pipelines or optimizing ETL operations, we enable businesses to achieve seamless integration and powerful analytics. Partner with dbaffinityto unlock the full value of your data and elevate your business intelligence strategy."
  ];
  
  return (
    <>
      <HeroSection backgroundImage={sqlImage} title="Microsoft SQL Expertise" />

      <WhoWeAre title="SQL Business Intelligence & Tools" content={content} imageSrc={server} />

      {/* <Customer/>

      <Connect /> */}
    </>
  );
};

export default Sql;
