import React from 'react'
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';
import governmentImg from "../../Images/govt.jpg";
import govpic from "../../Images/govtImg.jpg";

const Government = () => {
  const content = [
    "dbaffinity is dedicated to providing innovative cloud and IT solutions tailored specifically for the government sector. Their expertise helps government agencies enhance operational efficiency, improve service delivery, and ensure the security of sensitive data. By leveraging advanced technologies such as cloud computing, data analytics, and integration services, dbaffinityenables government organizations to streamline their internal processes, engage citizens more effectively, and foster greater transparency in public service.",
    
    "With deep knowledge of complex regulatory environments, dbaffinitydelivers solutions that meet compliance standards while optimizing resource allocation. The company's proficiency extends to supporting digital transformation initiatives, including the implementation of smart city technologies and improved data management systems. dbaffinityhelps government entities embrace modern technologies to stay ahead of evolving challenges and effectively respond to the growing demands of their communities.",
    
    "Through ongoing collaboration and strategic consulting, dbaffinityworks closely with government clients to ensure they harness the full potential of technology. By driving innovation and enhancing public services, dbaffinityhelps government agencies navigate digital change while delivering on their mission to improve citizen experiences and operational performance. Their commitment to supporting government entities ensures that they can adapt to the ever-evolving needs of the public sector."
  ];
  
  return (
    <>
      <HeroSection
        backgroundImage={governmentImg}
        title="Government "
      />
      <WhoWeAre title="Government Industry" content={content} imageSrc={govpic} />

          </>
  );
}

export default Government
