import React from 'react'
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';
import healthcare from "../../Images/healthcare.jpg";
import healthcareImg from "../../Images/healthcareIMG.jpg";


const HealthCare = () => {
  const content = [
    "dbaffinityplays a critical role in the healthcare sector by delivering customized cloud and IT solutions that enhance patient care, optimize operational efficiency, and ensure compliance with stringent regulatory standards. By utilizing advanced technologies such as data analytics, electronic health records (EHR) integration, and telehealth services, dbaffinityhelps healthcare organizations streamline their workflows and improve the overall patient experience. These solutions enable providers to respond more effectively to patient needs and enhance the delivery of care across various healthcare settings.",
    
    "dbaffinity's  expertise in managing sensitive patient data ensures that healthcare providers can securely store and share critical information while adhering to HIPAA and other regulatory requirements. Their solutions enable secure and efficient data exchange, improving care coordination and reducing the risk of errors. dbaffinityalso assists in the implementation of innovative solutions for remote monitoring and health analytics, which allow healthcare providers to proactively address patient health concerns and improve outcomes through data-driven insights and personalized care.",
    
    "By fostering collaboration among healthcare stakeholders and integrating the latest technologies, dbaffinity is committed to transforming the healthcare landscape. Their solutions help healthcare organizations drive operational efficiencies, improve clinical workflows, and ultimately enhance the quality of care delivered to patients. dbaffinity's  forward-thinking approach ensures that healthcare providers are equipped to meet the challenges of modern healthcare delivery while improving patient outcomes and fostering a more patient-centered experience."
  ];
  
  return (
    <>
      <HeroSection
        backgroundImage={healthcare}
        title="Health Care "
      />
      <WhoWeAre title="Health Care Industry" content={content} imageSrc={healthcareImg} />

          </>
  );
}

export default HealthCare
