import React from "react";
import HeroSection from "../Common/HeroSection";
import WhoWeAre from "../Common/WhoWeAre";
import about from "../Images/about.jpg";
import aboutone from "../Images/aboutone.jpg";
import Card from "../Common/Card";
import { Container, Row, Col } from 'react-bootstrap';

const About = () => {
  // const content = [
  //   " OUR MISSION :  Passionate people providing innovative solutions for complex business challenges.",
  //   " OUR VALUES :   To Make a Positive Difference to all our clients, partners, employees, and the communities where we live for a positive impact.",
  //   " OUR VISION :     To be a global leader that leverages innovation and the power of people to build tomorrow’s solutions.",
  // ];

  const MissionIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path
        fill="#fd9135"
        d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-223.1L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6l29.7 0c33.7 0 64.9 17.7 82.3 46.6l44.9 74.7c-16.1 17.6-28.6 38.5-36.6 61.5c-1.9-1.8-3.5-3.9-4.9-6.3L232 256.9 232 480c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128-16 0zM432 224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 240a24 24 0 1 0 0-48 24 24 0 1 0 0 48zM368 321.6l0 6.4c0 8.8 7.2 16 16 16s16-7.2 16-16l0-6.4c0-5.3 4.3-9.6 9.6-9.6l40.5 0c7.7 0 13.9 6.2 13.9 13.9c0 5.2-2.9 9.9-7.4 12.3l-32 16.8c-5.3 2.8-8.6 8.2-8.6 14.2l0 14.8c0 8.8 7.2 16 16 16s16-7.2 16-16l0-5.1 23.5-12.3c15.1-7.9 24.5-23.6 24.5-40.6c0-25.4-20.6-45.9-45.9-45.9l-40.5 0c-23 0-41.6 18.6-41.6 41.6z"
      />
    </svg>
  );

  const ValuesIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path
        fill="#fd9135"
        d="M400 0L176 0c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8L24 64C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9L192 448c-17.7 0-32 14.3-32 32s14.3 32 32 32l192 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-26.1 0C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24L446.4 64c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112l84.4 0c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6l84.4 0c-5.1 66.3-31.1 111.2-63 142.3z"
      />
    </svg>
  );

  const VisionIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path
        fill="#fd9135"
        d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
      />
    </svg>
  );

  const content =["At dbaffinity, we are fueled by a mission to harness the passion and dedication of our people to deliver innovative solutions for the most complex business challenges. We strive to empower our clients with cutting-edge technologies and tailored strategies that enable them to excel in today’s competitive landscape. Every solution we provide is a reflection of our commitment to excellence and problem-solving ingenuity. Our values are rooted in the belief that we can make a meaningful and positive difference to everyone we serve. Whether it’s our clients, partners, employees, or the communities where we live and work, we are dedicated to fostering growth, trust, and lasting relationships.","By prioritizing integrity and collaboration, we aim to create a ripple effect of positive change that resonates far beyond our immediate interactions. Our vision is bold yet achievable: to emerge as a global leader that combines innovation with the collective power of people to design and build the solutions of tomorrow. At dbaffinity, we believe that the future is shaped by those who dare to think differently and act decisively. With this mindset, we continuously innovate, adapt, and evolve to meet the ever-changing needs of the world. Together, we aspire to not just be participants in the future but architects of it."]

  return (
    <>
      <HeroSection backgroundImage={about} title="About us" />

      <WhoWeAre title="Overview" content={content} imageSrc={aboutone} />

      <Container>
        <Row className="cards-section">
          <Col xs={12} sm={12} md={6} lg={4} className="mt-3">
          <Card
          icon={MissionIcon}
          title="OUR MISSION"
          description="Driven professionals delivering cutting-edge solutions that address complex business challenges, transforming ideas into impactful results, and empowering growth with innovative technology and strategic insights."
        /></Col>
          <Col xs={12} sm={12} md={6} lg={4} className="mt-3">
          <Card
          icon={ValuesIcon}
          title="OUR VALUES"
          description="Create lasting change by dedicating efforts to serve our clients, partners, employees, and communities, ensuring a positive and meaningful impact for all involved."
        />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="mt-3">
          <Card
          icon={VisionIcon}
          title="OUR VISION"
          description="To become a global leader by harnessing innovation and the strength of our people to create solutions that shape the future."
        /></Col>
        </Row>
      </Container>

      {/* <div className="cards-section">
        <Card
          icon={MissionIcon}
          title="OUR MISSION"
          description="Driven professionals delivering cutting-edge solutions that address complex business challenges, transforming ideas into impactful results, and empowering growth with innovative technology and strategic insights."
        />
        <Card
          icon={ValuesIcon}
          title="OUR VALUES"
          description="Create lasting change by dedicating efforts to serve our clients, partners, employees, and communities, ensuring a positive and meaningful impact for all involved."
        />
        <Card
          icon={VisionIcon}
          title="OUR VISION"
          description="To become a global leader by harnessing innovation and the strength of our people to create solutions that shape the future."
        />
      </div> */}

      {/* <Customer/>

      <Connect/> */}
    </>
  );
};

export default About;
