import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import dbaffinityLogo from "../Images/logo.jpg";


const Header = () => {



  return (
    <>
    <div className="header">

      <Navbar expand="lg" className="navigation d-flex justify-content-between w-100">                
        <Navbar.Brand href="#">
          <Link to="/">
            <img className="logo-img" src={dbaffinityLogo} alt="dbaffinity" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/about">About</Nav.Link>

            <NavDropdown title="Expertise" id="services-dropdown" href="">
              <NavDropdown
                title="Oracle"
                id="more-services-dropdown"
                drop="end"
                className="blue_border py-0"
              >
                <NavDropdown.Item href="/expertise/oracle/cloud">
                  Oracle Cloud
                </NavDropdown.Item>
                <NavDropdown.Item href="/expertise/oracle/middleware">
                  {" "}
                  Middleware and integration{" "}
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Microsoft"
                id="more-services-dropdown"
                drop="end"
                className="py-0 blue_border"
              >
                <NavDropdown.Item href="/expertise/microsoft/azure">
                  Azure
                </NavDropdown.Item>
                <NavDropdown.Item href="/expertise/microsoft/sql">
                  SQL Server & Bi
                </NavDropdown.Item>
                <NavDropdown.Item href="/expertise/microsoft/.net">
                  .Net
                </NavDropdown.Item>
                <NavDropdown.Item href="/expertise/microsoft/m-dynamic">
                  Microsoft Dynamic{" "}
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown.Item href="/expertise/salesforce">
                Salesforce
              </NavDropdown.Item>
              <NavDropdown.Item href="/expertise/aws">
                Amazon Web Services
              </NavDropdown.Item>
              <NavDropdown.Item href="/expertise/staffing">
                Staffing
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Industries" id="products-dropdown">
              <NavDropdown.Item href="/industries/automotive">
                Automotive
              </NavDropdown.Item>
              <NavDropdown.Item href="/industries/govt">
                Government
              </NavDropdown.Item>
              <NavDropdown.Item href="/industries/health-care">
                Healthcare
              </NavDropdown.Item>
              <NavDropdown.Item href="/industries/insurance">
                Insurance
              </NavDropdown.Item>
              <NavDropdown.Item href="/industries/manufacturing">
                Manufacturing
              </NavDropdown.Item>
            </NavDropdown>

            {/* <NavDropdown title="Careers" id="products-dropdown">
              <NavDropdown.Item href="/open-positions/search-jobs">
                Search Jobs
              </NavDropdown.Item>
              <NavDropdown.Item href="/Open Positions/submit-resume">Submit Resume</NavDropdown.Item>
            </NavDropdown> */}

                      <Nav.Link href="/careers">Careers</Nav.Link>
            

            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      </div>
    </>
  );
};

export default Header;

// import React from "react";
// import { Link } from "react-router-dom";
// import dbaffinityLogo from "../Images/logo.png";
// import menu from "../Images/menu.png"

// const Header = () => {
//   return (
//     <>
//       <header class="header">
//         <div className="logo">
//           <Link to="/">
//             <img src={dbaffinityLogo} alt="dbaffinity" />
//           </Link>
//         </div>

//         <input type="checkbox" id="toggle" />
//         <label for="toggle">
//           {" "}
//           <img src={menu} alt=" menu" />
//         </label>

//         <nav class="navigation">
//           <ul>
//             <li>
//               <a href="/about">About</a>
//             </li>

//             <li>
//               <a href="">Expertise</a>
//               <ul>
//                 <li>
//                   <a href="">Oracle</a>

//                   <ul>
//                     <li>
//                       <a href="/expertise/oracle/cloud">Oracle Cloud </a>
//                     </li>
//                     <li>
//                       <a href="/expertise/oracle/middleware">
//                         Middleware and integration{" "}
//                       </a>
//                     </li>
//                   </ul>
//                 </li>

//                 <li>
//                   <a href="">Microsoft</a>

//                   <ul>
//                     <li>
//                       <a href="/expertise/microsoft/azure">Azure </a>
//                     </li>
//                     <li>
//                       <a href="/expertise/microsoft/sql">SQL server & Bi </a>
//                     </li>
//                     <li>
//                       <a href="/expertise/microsoft/.net">.NET</a>
//                     </li>
//                     <li>
//                       <a href="/expertise/microsoft/m-dynamic">
//                         Microsoft Dynamic{" "}
//                       </a>
//                     </li>
//                   </ul>
//                 </li>

//                 <li>
//                   <a href="/expertise/salesforce">Salesforce </a>
//                 </li>

//                 <li>
//                   <a href="/expertise/aws">Amazon web services </a>
//                 </li>
//                 <li>
//                   <a href="/expertise/staffing">Staffing </a>
//                 </li>
//               </ul>
//             </li>

//             <li>
//               <a href="">Industries</a>
//               <ul>
//                 <li>
//                   <a href="/industries/automotive">Automotive </a>
//                 </li>
//                 <li>
//                   <a href="/industries/govt">Government </a>
//                 </li>
//                 <li>
//                   <a href="/industries/health-care">Health Care </a>
//                 </li>
//                 <li>
//                   <a href="/industries/insurance">Insurance </a>
//                 </li>
//                 <li>
//                   <a href="/industries/manufacturing">Manufacturing </a>
//                 </li>
//               </ul>
//             </li>

//             <li>
//               <a href="">Careers</a>

//               <ul>
//                 <li>
//                   <a href="/careers/search-jobs">Search Jobs </a>
//                 </li>
//                 <li>
//                   <a href="/careers/submit-resume">Submit Resume </a>
//                 </li>
//               </ul>
//             </li>

//             <li>
//               <a href="/contact">Contact Us</a>
//             </li>
//           </ul>
//         </nav>
//       </header>
//     </>
//   );.
// };

// export default Header;
