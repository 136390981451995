import React from 'react'
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';
import SalesforceImg from "../../Images/CRM.jpg";
import salesforcepic from "../../Images/salesforce.jpg";

const Salesforce = () => {
  const content = [
    "dbaffinity is a trusted partner specializing in Salesforce solutions, empowering businesses to unlock the full potential of their customer relationship management (CRM) strategies. By leveraging the robust Salesforce platform, dbaffinityoffers customized implementations, seamless integrations, and tailored solutions that cater to the specific needs of each organization. Whether it’s streamlining operations or enhancing customer interactions, their expertise ensures businesses can achieve their strategic goals effectively. With a commitment to excellence, dbaffinityhelps clients maximize the value of Salesforce to drive efficiency and deliver impactful results across their business landscape.",
    
    "dbaffinity's  proficiency spans a wide range of Salesforce products, including Sales Cloud, Service Cloud, and Marketing Cloud. These tools enable clients to refine their sales pipelines, improve customer service responsiveness, and execute targeted marketing campaigns with precision. By adhering to best practices and industry standards, dbaffinityensures that each implementation aligns perfectly with business objectives. Their ongoing support and strategic guidance help organizations stay agile in the dynamic digital era, enabling long-term growth and customer satisfaction. Partnering with dbaffinitymeans leveraging Salesforce to foster innovation, optimize workflows, and achieve sustainable success."
  ];
  
  return (
    <>
      <HeroSection
        backgroundImage={SalesforceImg}
        title="Salesforce"
      />
      <WhoWeAre title="Salesforce" content={content} imageSrc={salesforcepic} />

          </>
  );
}

export default Salesforce
