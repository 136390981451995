import React, { useState } from "react";
import opportunity from "../../Images/opportunity.jpg";
import HeroSection from "../../Common/HeroSection";
import jobs from "../../assests/data/jobs.json";
import { toast, ToastContainer } from "react-toastify"; // Ensure toast is imported

const SearchJob = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [isApplying, setIsApplying] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    qualification: ''
  });

  console.log(selectedJob,"selectedJob", isApplying,"isApplying");
  

  const handleViewDetails = (job) => {
    setSelectedJob(job);
  };

  const handleBackToOpenings = () => {
    setSelectedJob(null);
  };

  const handleBacK =()=>{
    setIsApplying(false)
  }

  const handleApply = () => {
    setIsApplying(true);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleBackToJobSection = async (e) => {
    e.preventDefault();

    try {
      const form = new FormData();
      form.append('access_key', 'da6a0983-44cb-49e0-bb72-8361a4cde658');
      form.append('subject', 'New Job Application Submission');
      form.append('from_name', 'Your Company Name');
      form.append('name', formData.name);
      form.append('email', formData.email);
      form.append('phone', formData.phone);
      form.append('qualification', formData.qualification);
      form.append('job_title', selectedJob?.title || "");
      form.append('job_id', selectedJob?.id || "");

      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: form,
      });
      console.log(response, "Response")
      const data = await response.json();
      console.log(data, "data")
      if (data.success) {
        console.log(data)
        toast.success("Application submitted successfully!");
        setFormData({ name: '', email: '', phone: '', qualification: '' }); // Reset form
        handleBackToOpenings(); // Reset to job openings view
      } else {
        toast.error(data.message || "Something went wrong.");
      }
    } catch (error) {
      toast.error("Network error: Unable to submit the form.");
      console.error("Error:", error);
    }   
  };

  return (
    <>
      <HeroSection
        backgroundImage={opportunity}
        title="Careers"
      />
      <div className="job-section">
        {isApplying ? (
          // Form Section
          <div className="form-section">
            <form onSubmit={handleBackToJobSection}>
              {/* Required Web3Forms hidden fields */}
              <input type="hidden" name="access_key" value="e13a86b6-0d1a-44aa-916b-4ece4fabb587" />
              {/* <input
                type="hidden"
                name="redirect"
                value="http://localhost:3000/open-positions/search-jobs"
              /> */}
              <input type="hidden" name="subject" value="New Job Application Submission" />
              <input type="hidden" name="from_name" value="Your Company Name" />

              <h2>Submit Your Resume</h2>

              {/* Job Title Field */}
              <label htmlFor="title">Job Title</label>
              <input
                type="text"
                id="title"
                name="Job Title"
                value={selectedJob?.title || ""}
                readOnly
                required
              />

              {/* Job ID Field */}
              <label htmlFor="jobId">Job ID</label>
              <input
                type="text"
                id="jobId"
                name="Job ID"
                value={selectedJob?.id || ""}
                readOnly
                required
              />

              {/* Name Field */}
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                placeholder="Enter your full name"
                onChange={handleInputChange}
                required
              />

              {/* Email Field */}
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                placeholder="Enter your email address"
                onChange={handleInputChange}
                required
              />

              {/* Phone Field */}
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                placeholder="Enter your phone number"
                onChange={handleInputChange}
                pattern="[0-9]{10}"
                title="Enter a valid 10-digit phone number"
                required
              />

              {/* Qualification Field */}
              <label htmlFor="qualification">Qualification</label>
              <input
                type="text"
                id="qualification"
                name="qualification"
                value={formData.qualification}
                placeholder="Enter your qualification"
                onChange={handleInputChange}
                required
              />

              {/* Submit Button */}
              <button type="submit" className="view-details mt-3">
                Submit
              </button>

              {/* Back to Openings Button */}
              <button
                type="button"
                onClick={handleBacK}
                className="view-details mt-3"
              >
                Back to Openings
              </button>
            </form>
          </div>
        ) : (
          // Job Openings Section
          <div className="openings-section">
            <h2>Open Positions</h2>
            {selectedJob ? (
              <div className="job-card">
                <h2>{selectedJob.title}</h2>
                <h3>Openings - {selectedJob.openings}</h3>
                <p>{selectedJob.description}</p>
                <p>{selectedJob.Overview}</p>
                <p>{selectedJob.requirement}</p>
                <button className="view-details" onClick={handleBackToOpenings}>Back to Openings</button>
                <button onClick={handleApply} className="apply-button">
                  Apply
                </button>
              </div>
            ) : (
              jobs.map((job, index) => (
                <div key={index} className="job-card">
                  <h2>{job.title}</h2>
                  <h3>Openings - {job.openings}</h3>
                  <p>{job.description}</p>
                  <button
                    onClick={() => handleViewDetails(job)}
                    className="view-details"
                  >
                    View Details
                  </button>
                </div>
              ))
            )}
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default SearchJob;
