import React, { useState } from "react";
import { FaMapPin, FaMobileAlt } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    toast.info("Sending..."); // Show a "Sending..." message
    const formData = new FormData(event.target);

    formData.append("access_key", "e13a86b6-0d1a-44aa-916b-4ece4fabb587");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        toast.success("Form Submitted Successfully!");
        event.target.reset();
      } else {
        toast.error(data.message || "Something went wrong.");
      }
    } catch (error) {
      toast.error("Network error: Unable to submit the form.");
      console.error("Error:", error);
    }
  };

  return (
    <>
      <ToastContainer /> {/* This renders the toast messages */}
      <div className="contact">
        <div className="content">
          <h2>Contact</h2>
          <p>We're Here to Help – Reach Out Anytime!</p>
        </div>

        <div className="container width-90">
          <Row className="w-100">
            <Col xs={12} sm={12} md={6} lg={3} className="box d-flex mt-4 custom-right">
              <div className="icon">
                <FaMapPin />
              </div>
              <div className="text">
                <h2>Address</h2>
                <p>dbaffinity</p>
                <p>545 Metro Place South, Suite 100</p>
                <p>Dublin OH 43017</p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={3} className="box d-flex mt-4 custom-right">
              <div className="icon">
                <FaMobileAlt />
              </div>
              <div className="text">
                <h2>Phone</h2>
                <p>(614) 760-3707</p>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={3} className="contactForm mt-4 custom-move-right">
              <form onSubmit={onSubmit}>
                <h2 className="text-center">Leave Your Message Here</h2>
                <p>Fill out the form and we'll get in touch with you right away!</p>

                <div className="inputBox">
                  <input type="text" name="name" required />
                  <span>Full Name</span>
                </div>
                <div className="inputBox">
                  <input type="email" name="email" required />
                  <span>Email</span>
                </div>
                <div className="inputBox">
                  <textarea name="message" required></textarea>
                  <span>Message</span>
                </div>
                <div className="inputBox">
                  <input type="submit" value="Send" />
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ContactUs;