import React from 'react'
import oracleImg from "../../Images/oracle-cloud.jpg";
import oracle from "../../Images/oracle.png";
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';

const Oracle = () => {

  const content = [
    "dbaffinity is committed to delivering cutting-edge cloud solutions that not only streamline operations but also elevate business performance through Oracle Cloud services. By combining industry-leading technology with unparalleled expertise, dbaffinityhelps businesses harness the full potential of Oracle Cloud, enabling them to stay ahead in an increasingly competitive market. Their strategic approach includes a detailed analysis of client requirements, followed by customized solutions that enhance scalability, improve operational efficiency, and drive innovation.",

"With an emphasis on security and data integrity, dbaffinityensures businesses benefit from Oracle’s robust cloud security features, minimizing risks and optimizing compliance. Through continuous monitoring, advanced analytics, and proactive performance management, dbaffinityempowers clients to achieve long-term success and growth.",

"Furthermore, dbaffinity's  team of certified experts is dedicated to providing ongoing support and training, enabling businesses to maximize their ROI on Oracle Cloud solutions. Their agile methodology ensures rapid deployment of cloud resources, reducing downtime and accelerating time-to-value. By fostering a collaborative environment, dbaffinitybuilds lasting relationships with clients, becoming a trusted advisor and catalyst for their digital transformation journeys."
  ];
  return (
    <>
      <HeroSection
        backgroundImage={oracleImg}
        title="Oracle cloud"
      />
      <WhoWeAre title="Oracle cloud" content={content} imageSrc={oracle} />

          </>
  );
}

export default Oracle
