import React, { useState, useEffect } from "react";

import ERP from "../Images/services/ERP.jpg";
import db from "../Images/services//db.jpg";
import ProfessionalService from "../Images/services//professional serive.jpg";
import project from "../Images/services//project.jpg";

import WhoWeAre from "../Common/WhoWeAre";
import whoarewe from "../Images/whoweare.jpg";
import Connect from "../Common/Connect";
import Customer from "../Common/Customer";

import { Container, Row, Col } from "react-bootstrap";

const Home = () => {
  const phrases = [
    "digital transformation services",
    "AWS cloud innovations",
    "Netsuite expertise",
    "salesforce implementations",
    "reliable database management",
  ];

  const content = [
    "dbaffinityspecializes in delivering Remote ERP Management Services and Database Management Services, tailored to meet the unique needs of small and mid-sized corporations, as well as local, state, and federal government agencies. Our mission is to help organizations optimize their IT operations while ensuring seamless system performance and reliability. By offering targeted solutions, we empower our clients to focus on their core business objectives, free from the burden of day-to-day IT management.",

    "Our ERP Management Services are specifically designed to support Oracle E-Business applications. We provide comprehensive support, ranging from routine maintenance to troubleshooting and optimization. In the realm of Database Management Services, we cover the entire lifecycle of Oracle and Microsoft SQL Server databases. From setup and configuration to performance tuning and backup strategies, our services are engineered to ensure robust database performance and security.",

    "At dbaffinity, we adopt a team-based approach to managing IT environments, offering 24/7/365 support to ensure uninterrupted operations. Our dedicated team of experts works as an extension of your IT staff, taking care of daily maintenance tasks so your team can concentrate on driving strategic business initiatives. This collaborative approach not only enhances operational efficiency but also ensures that your IT infrastructure aligns with your long-term goals.",

    "What sets us apart is our commitment to delivering remote support while staying onshore in North America. This strategy guarantees unparalleled accessibility, round-the-clock availability, and stringent data security measures. By keeping our operations close to our clients, we deliver substantial cost savings without compromising on the quality of service. With dbaffinityas your partner, you can rest assured that your ERP and database environments are in capable hands, allowing you to achieve greater productivity and operational excellence.",
  ];

  const [currentPhrase, setCurrentPhrase] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhrase((prev) => (prev + 1) % phrases.length);
    }, 4000); // Update text every 4 seconds to match animation duration

    return () => clearInterval(interval);
  }, [phrases[currentPhrase]]);

  return (
    <>
      <div className="hero">
        <h1>Optimise your business with</h1>
        <h2 key={currentPhrase}>{phrases[currentPhrase]}</h2>
      </div>

      <WhoWeAre title="Who We Are" content={content} imageSrc={whoarewe} />

      <Customer />

      <Container fluid className="px-4">
        <div className="services">
          <h1>Services we offer</h1>
          <p>dbaffinityoffers the following services:</p>
          <Row>
            <Col xs={12} sm={6} md={6} lg={3} className="px-4">
              <div className="service-card">
                <img src={ERP} alt="ERP Management Services" />
                <div className="content">
                  <h3>ERP Management Services</h3>
                  <p>
                    Our ERP management services focus on delivering expert
                    support for Oracle E-Business Applications, ensuring smooth
                    integration and peak performance.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3} className="px-4">
              <div className="service-card">
                <img src={db} alt="ERP Management Services" />
                <div className="content">
                  <h3>Database Management Services</h3>
                  <p>
                    Our DB Management Services encompass comprehensive lifecycle
                    support & management for Oracle and Microsoft SQL Server
                    databases.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3} className="px-4">
              <div className="service-card">
                <img src={ProfessionalService} alt="ERP Management Services" />
                <div className="content">
                  <h3>Professional Services</h3>
                  <p>
                    Our Professional Services specialise in Staff Augmentation
                    and supplemental support with onsite consultants on a Time
                    and Materials basis.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={3} className="px-4">
              <div className="service-card">
                <img src={project} alt="ERP Management Services" />
                <div className="content">
                  <h3>Project Based Services</h3>
                  <p>
                    dbaffinityprovides this service for specialised DBA
                    projects, which demand additional time and effort and are
                    negotiated on a project-specific basis.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Connect />
    </>
  );
};

export default Home;
