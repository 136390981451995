import React from "react";
import { useNavigate } from "react-router-dom";

const Connect = () => {
    const navigate = useNavigate();
  const handleConnectClick = () => {
    navigate("/contact");
  };
  return (
    <div className="connect">
      <div className="connect-content">
        <h1>Lets Get Started</h1>
        <p>The Platforms where Technology Meets Human Expertise</p>
        <blockquote>
          "What differentiates us from the rest of the market players is the
          fact that we combine human experience and knowledge to get the best
          results from the technology."
        </blockquote>
        <button className="connect-button" onClick={handleConnectClick}>
          LETS CONNECT
        </button>
      </div>
    </div>
  );
};

export default Connect;
