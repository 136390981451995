import React from "react";
import honda from "../Images/Honda.png";
import safellite from "../Images/safellite.png";
import toyota from "../Images/toyota.png";
import vitamix from "../Images/vitamix.png";
import dhl from "../Images/Dhl.png";
import dell from "../Images/dell.jpg";
import activision from "../Images/activision.png";
import sandiego from "../Images/sandiego.png";
import franklin from "../Images/franklin.jpg";
import { Container } from "react-bootstrap";

const Customer = () => {
  return (
    <Container fluid className="mt-5 px-4">
      <div className="customers">
        <h1>Our Customers</h1>
        <p>
          Our clients are our partners, integral to our two-decade journey. From
          startups to global corporations, we've enriched businesses with
          innovation, ensuring every partnership thrives in the digital age.
        </p>

        <div className="logo-wrapper">
          <div className="logo-container">
            {/* First set of logos */}
            <div className="logo-item">
              <img src={honda} alt="Customer 2 Logo" />
            </div>
            <div className="logo-item">
              <img src={safellite} alt="Customer 3 Logo" />
            </div>
            <div className="logo-item">
              <img src={toyota} alt="Customer 1 Logo" />
            </div>
            <div className="logo-item">
              <img src={vitamix} alt="Customer 2 Logo" />
            </div>
            <div className="logo-item">
              <img src={dhl} alt="Customer 3 Logo" />
            </div>
            <div className="logo-item">
              <img src={dell} alt="Customer 1 Logo" />
            </div>
            <div className="logo-item">
              <img src={activision} alt="Customer 2 Logo" />
            </div>
            <div className="logo-item">
              <img src={sandiego} alt="Customer 3 Logo" />
            </div>
            <div className="logo-item">
              <img src={franklin} alt="Customer 1 Logo" />
            </div>

            {/* Duplicate logos for smooth looping */}
            <div className="logo-item">
              <img src={honda} alt="Customer 2 Logo" />
            </div>
            <div className="logo-item">
              <img src={safellite} alt="Customer 3 Logo" />
            </div>
            <div className="logo-item">
              <img src={toyota} alt="Customer 1 Logo" />
            </div>
            <div className="logo-item">
              <img src={vitamix} alt="Customer 2 Logo" />
            </div>
            <div className="logo-item">
              <img src={dhl} alt="Customer 3 Logo" />
            </div>
            <div className="logo-item">
              <img src={dell} alt="Customer 1 Logo" />
            </div>
            <div className="logo-item">
              <img src={activision} alt="Customer 2 Logo" />
            </div>
            <div className="logo-item">
              <img src={sandiego} alt="Customer 3 Logo" />
            </div>
            <div className="logo-item">
              <img src={franklin} alt="Customer 1 Logo" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Customer;
