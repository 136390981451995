import React from 'react'
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';
import manu from "../../Images/manufacture.jpg";
import manuImg from "../../Images/manu.jpg";

const Manufacture = () => {
  const content = [
    "dbaffinity is a leading force in the manufacturing sector, offering cutting-edge cloud and IT solutions designed to enhance operational efficiency, optimize production processes, and drive digital transformation. Leveraging advanced technologies such as the Internet of Things (IoT), data analytics, and machine learning, dbaffinityempowers manufacturers to gain real-time insights into their operations. This allows businesses to streamline supply chain management, improve quality control, and implement smarter, data-driven decision-making across all levels of production.",
    
    "With deep expertise in smart manufacturing practices, dbaffinityhelps organizations automate workflows, minimize downtime, and increase overall productivity. Their solutions enable seamless integration of disparate systems, allowing manufacturers to achieve continuous data flow throughout their operations, ensuring that every aspect of the production process is connected and optimized. By implementing innovative technologies, dbaffinitysupports manufacturers in improving both operational efficiency and product quality, enabling them to stay competitive in the rapidly evolving digital marketplace.",
    
    "dbaffinity's  commitment to sustainability and continuous improvement helps manufacturers adapt to changing market demands and embrace the latest industry trends. Their tailored solutions foster innovation and provide the tools necessary to maintain a competitive edge. With ongoing support and strategic consulting, dbaffinity is dedicated to driving growth, operational excellence, and long-term success for manufacturing businesses. By partnering with dbaffinity, manufacturers can navigate the complexities of digital transformation and position themselves for success in the future."
  ]
  
  return (
    <>
      <HeroSection
        backgroundImage={manu}
        title="Manufacturing "
      />
      <WhoWeAre title="Manufacture Industry" content={content} imageSrc={manuImg} />

          </>
  );
}
export default Manufacture
