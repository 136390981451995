import React from "react";
import { Container, Row } from "react-bootstrap";

const PrivatePolicy = () => {
  return (
    <Container  className="mt-5 px-4 pt-5 ">
      <Row>
        
          <div className="whoweare-content">
            <h1 className="mb-4">PRIVACY POLICY</h1>
            
            <h2>PRIVACY POLICY</h2>
            <p className="mb-4">
              dbaffinity is committed to protecting your privacy and ensuring
              that your personal information is handled in a safe and
              responsible manner. This Privacy Policy explains how we collect,
              use, disclose, and protect your personally identifiable
              information when you interact with our website, use our services,
              or communicate with us. By accessing our website or using our
              services, you agree to the collection and use of information in
              accordance with this policy.
            </p>

            <h2> INFORMATION COLLECTION </h2>
            <p className="mb-4">
              We will only collect personally identifiable information (such as
              your name, address, phone number, or email address) that you
              provide voluntarily through our website or email communication.
              Additionally, we may gather general information (such as your
              browser type, the files you request, and the domain name and
              country from which you access our site) to enhance our website and
              improve your experience
            </p>
            <h2>USE OF INFORMATION</h2>
            <p className="mb-4">
              dbaffinityuses your information to respond to your questions or
              requests for information about our services, products, or company.
              We use it to provide you with the services you have requested,
              including customized solutions or updates regarding our software
              and services. We will never sell or rent your personal information
              to third parties. We may disclose your personal information to
              comply with legal obligations, such as responding to subpoenas,
              court orders, or other legal processes.
            </p>
            <h2>COOKIES</h2>
            <p className="mb-4">
              Our website may utilize a technology known as "cookies" to enhance
              user experience by providing personalized content. Cookies are
              small data files transmitted to your web browser and stored on
              your device. These files enable our server to recognize returning
              visitors, thus improving the functionality of our website. Users
              have the option to configure their browser settings to block
              cookies or to receive notifications when cookies are being sent.
              Please note that disabling cookies may impact the performance and
              usability of certain website features.
            </p>
            <h2>PRIVACY POLICY CHANGES</h2>
            <p className="mb-4">
              We reserve the right to update or change this privacy policy at
              any time. Any such changes will be promptly reflected on this
              page.
            </p>
          </div>
        
      </Row>
    </Container>
  );
};

export default PrivatePolicy;
