import './Styles.css';
import { Routes, Route } from 'react-router-dom';
import Header from './Common/Header';
import Home from './Components/Home';
import About from './Components/About';
import ContactUs from './Components/ContactUs';
import Footer from './Common/Footer';


import Expertise from './Components/ExpertisePages/Expertise';
import Azure from './Components/ExpertisePages/Azure';
import Sql from './Components/ExpertisePages/Sql';
import DotNet from './Components/ExpertisePages/Dotnet';
import Dynamic from './Components/ExpertisePages/Dynamic';
import Oracle from './Components/ExpertisePages/Oracle';

import Middleware from './Components/ExpertisePages/Middleware';
import Aws from './Components/ExpertisePages/Aws';
import Staffing from './Components/ExpertisePages/Staffing';

import Industries from './Components/IndustryPages/Industries';
import Automotive from './Components/IndustryPages/Automotive';
import Government from './Components/IndustryPages/Government';
import HealthCare from './Components/IndustryPages/HealthCare';
import Insurance from './Components/IndustryPages/Insurance';
import Manufacturing from './Components/IndustryPages/Manufacture';

import SearchJob from './Components/CareerPages/SearchJob';

import Salesforce from './Components/ExpertisePages/Salesforce';
import PrivatePolicy from './Components/PrivatePolicy';


function App() {
  return (
    < >
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />

        <Route path='/about' element={<About />} />

        <Route path='/expertise' element={<Expertise />}>
          <Route path="microsoft/azure" element={<Azure />} />
          <Route path="microsoft/sql" element={<Sql />} />
          <Route path="microsoft/.net" element={<DotNet />} />
          <Route path="microsoft/m-dynamic" element={<Dynamic />} />
          <Route path="oracle/cloud" element={<Oracle />} />
          <Route path="oracle/middleware" element={<Middleware />} />
          <Route path="salesforce" element={<Salesforce />} />
          <Route path="aws" element={<Aws />} />
          <Route path="staffing" element={<Staffing />} />
        </Route>

        <Route path='/industries' element={<Industries />}>
          <Route path="automotive" element={<Automotive />} />
          <Route path="govt" element={<Government />} />
          <Route path="health-care" element={<HealthCare />} />
          <Route path="insurance" element={<Insurance />} />
          <Route path="manufacturing" element={<Manufacturing />} />
        </Route>

        {/* <Route path='/open-positions' element={<Career />}>
          <Route path="search-jobs" element={<SearchJob />} />
          <Route path="submit-resume" element={<SubmitResume />} />
        </Route> */}

        <Route path='/careers' element={<SearchJob />} />


        <Route path='/contact' element={<ContactUs />} />
        <Route path='/private-policy' element={<PrivatePolicy />} />

        
      </Routes>
      <Footer />
    </>
  );
}


export default App;