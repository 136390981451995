import React from 'react'
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';
import Insurancee from "../../Images/insurance.jpg";
import insuranceImg from "../../Images/insuranceImg.jpg";

const Insurance = () => {
  const content = [
    "dbaffinity is a trusted partner in the insurance industry, offering innovative cloud and IT solutions that enhance operational efficiency, improve customer service, and streamline claims processing. By leveraging cutting-edge technologies like data analytics, artificial intelligence, and integration services, dbaffinityhelps insurers gain valuable insights into customer behavior, optimize risk assessment processes, and customize offerings to meet the unique needs of policyholders. These capabilities allow insurance companies to stay ahead of the competition and deliver enhanced services to their clients.",
    
    "With dbaffinity's  expertise in implementing robust claims management systems, insurance organizations can process claims faster and more accurately, reducing turnaround times and boosting customer satisfaction. Their solutions also support compliance with regulatory standards by providing secure data management and advanced reporting tools. This ensures that insurers can meet legal requirements while improving operational effectiveness. By fostering efficiency and security, dbaffinityhelps insurance companies stay compliant while optimizing business operations.",
    
    "dbaffinity is committed to helping insurance companies navigate the challenges of a rapidly changing industry by driving digital transformation and innovation. Their solutions enable organizations to enhance business processes, improve customer engagement, and deliver exceptional value to clients. With dbaffinity's  support, insurers can remain agile and competitive in a dynamic market, offering personalized services that build trust and loyalty among policyholders."
  ];
  
  return (
    <>
      <HeroSection
        backgroundImage={Insurancee}
        title="Insurance "
      />
      <WhoWeAre title="Insurance Industry" content={content} imageSrc={insuranceImg} />

          </>
  );
}
export default Insurance
