import React from "react";

const HeroSection = ({ backgroundImage, title }) => (
  <div
    className="hero"
    style={{
      background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backgroundImage}) no-repeat center center/cover`,
    }}
  >
    <h1>{title}</h1>
  </div>
);

export default HeroSection;
