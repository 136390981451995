import React from "react";
import HeroSection from "../../Common/HeroSection";
import dotNetImage from "../../Images/dotNetImage.jpg";
import net from "../../Images/net.jpg";
import WhoWeAre from "../../Common/WhoWeAre";

const Dotnet = () => {
  const content = [
    "dbaffinityempowers businesses to harness the full potential of the .NET framework, a unique and versatile platform for designing, developing, deploying, and integrating applications. With its rich set of features and capabilities, .NET allows organizations to create robust, scalable, and secure solutions tailored to their specific needs. Our team of experienced consultants specializes in object-oriented requirements gathering, enabling efficient project planning and strategy formulation. By leveraging .NET's strengths, we help clients streamline their development processes and achieve their technical objectives with precision and efficiency. From conception to deployment, we ensure every project is executed with the highest standards of quality and reliability.",
    
    "At dbaffinity, we adhere to industry best practices and Microsoft's .NET guidelines to deliver solutions perfectly aligned with your project's unique requirements. Our expertise spans the entire project lifecycle, from management and implementation to deployment and beyond. We take pride in providing continuous support even after project completion, ensuring optimal performance and client satisfaction. Whether building new applications or enhancing existing ones, our team is dedicated to delivering exceptional service and measurable results. Partner with dbaffinityto unlock the transformative power of .NET and achieve long-term success in your development endeavors."
  ];
  return (
    <>
      <HeroSection
        backgroundImage={dotNetImage}
        title="Microsoft .NET Expertise"
      />
      <WhoWeAre title=".NET: A Custom- oriented Framework" content={content} imageSrc={net} />

      {/* <Customer/>

      <Connect /> */}
    </>
  );
};

export default Dotnet;
