import React from "react";
import HeroSection from "../../Common/HeroSection";
import dynamicImg from "../../Images/dynamicImg.jpg";
import dynamic from "../../Images/dynamic.jpg";
import WhoWeAre from "../../Common/WhoWeAre";


const Dynamic = () => {
  const content = [
    "dbaffinityoffers cutting-edge Microsoft Dynamics solutions designed to transform leads into revenue through a comprehensive suite of ERP and CRM applications. These end-to-end business tools empower organizations to streamline their operations, enhance productivity, and drive sustainable growth. By analyzing your current business needs, we customize solutions that align with your goals, leveraging the powerful features of Microsoft Business Solutions. Our tailored approach ensures that tasks are prioritized effectively, enabling businesses to focus on what matters most. With dbaffinity, you gain a partner dedicated to maximizing the potential of your Microsoft Dynamics implementation.",
    
    "At dbaffinity, we assist businesses in deploying Microsoft CRM tools that cater to their unique requirements, improving sales, marketing, and customer service efforts. By utilizing the intuitive and robust features of Microsoft Dynamics, we help you build stronger customer relationships and drive engagement. Our team ensures seamless integration of these tools into your existing workflows, providing a complete tuning of your business processes for optimal results. From implementation to ongoing support, dbaffinity is committed to empowering businesses with solutions that elevate performance and deliver measurable success."
  ]
  
  return (
    <>
      <HeroSection
        backgroundImage={dynamicImg}
        title="Microsoft Dynamics Expertise"
      />
      <WhoWeAre title="Microsoft Dynamics CRM" content={content} imageSrc={dynamic} />

      {/* <Customer/>

      <Connect /> */}
    </>
  );
};

export default Dynamic;
