import React from 'react'
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';
import awsImg from "../../Images/aws.jpg";
import awspic from "../../Images/aws pic.jpg";

const Aws = () => {
  const content = [
    "dbaffinity is a visionary provider of cloud solutions, specializing in Amazon Web Services (AWS) to help businesses unlock the potential of the cloud for growth and innovation. With a focus on scalability, reliability, and flexibility, dbaffinitydelivers tailored solutions that enable organizations to adapt to the fast-changing demands of the digital landscape. Their expertise spans the comprehensive suite of AWS services, ensuring businesses can leverage cutting-edge tools to transform their operations and drive success. From planning to execution, dbaffinityensures every step of your AWS journey is seamless and impactful.",
    
    "Offering a wide range of AWS services, dbaffinitysupports businesses in deploying powerful solutions such as EC2 for compute power, S3 for storage, and RDS for managed databases. Their proven strategies for cloud migration, optimization, and management ensure maximum performance and cost-effectiveness, allowing clients to achieve their strategic goals efficiently. Additionally, dbaffinityenables organizations to harness advanced AWS features, including analytics, machine learning, and robust security, driving digital transformation and innovation. With ongoing support and expert consulting, dbaffinity is committed to empowering businesses to thrive in a competitive, ever-evolving marketplace."
  ];    
  
  return (
    <>
      <HeroSection
        backgroundImage={awsImg}
        title="Amazon Web Services"
      />
      <WhoWeAre title="Amazon Web Services" content={content} imageSrc={awspic} />

          </>
  );
}
export default Aws
