import React from "react";
import HeroSection from "../../Common/HeroSection";
import azureImage from "../../Images/azureImage.jpg";
import WhoWeAre from "../../Common/WhoWeAre";
import Cloud from "../../Images/Cloud.jpg";


const Azure = () => {
  const content = [
    "Microsoft Azure stands as a premier leader in Cloud Computing, delivering exceptional solutions in both Private and Public Cloud domains. It offers businesses unmatched scalability and flexibility, enabling seamless digital transformation. With services like Software as a Service (SaaS), Platform as a Service (PaaS), and Infrastructure as a Service (IaaS), Azure provides end-to-end support for application development and deployment. Its compatibility with multiple programming languages makes it a versatile choice for developers and enterprises alike. Azure empowers organizations to innovate, adapt, and thrive in an ever-evolving technological landscape.",
    
    "dbaffinity, a trusted Microsoft Azure Cloud Service Partner, helps businesses harness Azure's full potential to achieve strategic goals. We specialize in deploying powerful features such as Azure Security for robust protection, Azure AI for intelligent automation, and Azure SQL for optimized database management. Our team collaborates closely with clients to tailor cloud solutions that align with their specific needs and objectives. By leveraging Azure's advanced capabilities, we enable organizations to reduce costs, enhance performance, and streamline operations. With dbaffinity, businesses can confidently embrace Azure to drive growth and innovation."
  ];


  return (
    <>
      <HeroSection backgroundImage={azureImage} title="Microsoft Azure Expertise" />

      <WhoWeAre title="Profits from Microsoft Cloud Solutions" content={content} imageSrc={Cloud} />

      {/* <Customer/>

      <Connect/> */}
    </>
  );
};

export default Azure;
