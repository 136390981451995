import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapPin, FaMobileAlt, FaChevronRight } from "react-icons/fa";
import dbaffinityLogo from "../Images/dba_logo_transparent.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer-area">
        <Container>
          <Row>
            <Col sm={12} md={6} lg={4} className="px-3 px-lg-4">
              <div className="single-footer">
                <div className="logo">
                  <Link to="/">
                    <img src={dbaffinityLogo} alt="dbaffinity" />
                  </Link>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={2}>
              <div className="single-footer">
                <h4>Main Menu</h4>
                <ul>
                  <li>
                    <a href="/">
                      <FaChevronRight />
                      home
                    </a>{" "}
                  </li>
                  <li>
                    <a href="/about">
                      <FaChevronRight />
                      about 
                    </a>{" "}
                  </li>
                  <li>
                    <a href="/private-policy">
                      <FaChevronRight />
                      privacy policy
                    </a>{" "}
                  </li>
                  <li>
                    <a href="/open-positions/submit-resume">
                      <FaChevronRight />
                      submit resume
                    </a>{" "}
                  </li>
                  <li>
                    <a href="/contact">
                      <FaChevronRight />
                      contact
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={6} lg={2}>
              <div className="single-footer">
                <h4>Solutions</h4>
                <ul>
                  <li>
                    <a href="/expertise/oracle/cloud">
                      <FaChevronRight />
                      Oracle
                    </a>{" "}
                  </li>
                  <li>
                    <a href="/expertise/microsoft/azure">
                      <FaChevronRight />
                      Microsoft
                    </a>{" "}
                  </li>
                  <li>
                    <a href="/expertise/salesforce">
                      <FaChevronRight />
                      Salesforce
                    </a>{" "}
                  </li>
                  <li>
                    <a href="/expertise/aws">
                      <FaChevronRight />
                      Amazon web service
                    </a>{" "}
                  </li>
                  <li>
                    <a href="/expertise/staffing">
                      <FaChevronRight />
                      Staffing
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </Col>
            {/* <Col sm={12} md={6} lg={4}>
              <div className="single-footer">
                <h4>contact us</h4>
                <ul>
                  <li>
                    <a
                      href="https://www.google.com/maps/place/545+Metro+Pl+S+%23+100,+Dublin,+OH+43017,+USA/@40.092979,-83.132387,16z/data=!4m6!3m5!1s0x883893252959b263:0x71a93dc96287bba5!8m2!3d40.0929791!4d-83.1323869!16s%2Fg%2F11nsw29q_4?hl=en&entry=ttu"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaMapPin />
                      dbaffinityLLC. 545 Metro Place South Suite 100, Dublin OH
                      43017
                    </a>
                  </li>
                  <li>
                    <a href="tel:+16147603707">
                      <FaMobileAlt />
                      (614)760-3707
                    </a>
                  </li>
                </ul>
              </div>
            </Col> */}

            <Col sm={12} md={12} lg={12} className="mt-5">
              <div className="copy-right text-center pb-3 pt-2 ">
             
                &copy; 2025 COPYRIGHT - dbaffinity| All RIGHTS RESERVED 
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
