import React from 'react'
import HeroSection from '../../Common/HeroSection';
import WhoWeAre from '../../Common/WhoWeAre';
import staffImg from "../../Images/staffing.jpg";
import staffpic from "../../Images/staff-pic.jpg";

const Staffing = () => {
  const content =[
    "dbaffinity is committed to delivering outstanding staffing solutions that bridge the gap between businesses and top-tier talent in the IT and cloud services domains. With an acute awareness of the ever-changing technology landscape, dbaffinityexcels in sourcing skilled professionals for critical roles in cloud computing, software development, data management, and cybersecurity. Their ability to match expertise with organizational needs ensures businesses are equipped with the right talent to drive success and innovation.",
    
    "Offering a full spectrum of staffing services, dbaffinityspecializes in contract, permanent, and project-based placements to accommodate diverse business requirements. Their extensive network and industry expertise enable swift identification of highly qualified candidates, ensuring seamless integration into organizational teams. With a focus on aligning skills and cultural fit, dbaffinityenhances team dynamics and boosts overall productivity.",
    
    "dbaffinity's  dedication extends beyond placement by fostering long-term relationships with both clients and candidates. Their ongoing support ensures that businesses thrive in a competitive market while empowering professionals to achieve their career aspirations. By partnering with dbaffinity, organizations gain access to exceptional talent and strategic staffing solutions that fuel growth, innovation, and operational excellence."
  ]
  
  return (
    <>
      <HeroSection
        backgroundImage={staffImg}
        title="Staffing"
      />
      <WhoWeAre title="Staffing" content={content} imageSrc={staffpic} />

          </>
  );
}
export default Staffing
