import React from 'react';

const Card = ({ icon, title, description }) => (
  <div className="card">
    <div className="card-icon">{icon}</div>
    <h2 className="card-title">{title}</h2>
    <p className="card-description">{description}</p>
  </div>
);

export default Card;
