import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const WhoWeAre = ({ title, content, imageSrc }) => (
  <Container fluid className='mt-5 px-4 pb-4'>
  <Row>
    <Col xs={12} sm={12} md={12} lg={7}>
    <div className="whoweare-content">
      {/* <h1>{title}</h1> */}
      {content.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div></Col>
    <Col xs={12} sm={12} md={12} lg={5}>
     <div className="whoweare-image d-flex align-items-center">
      <img src={imageSrc} alt={title} className='w-100' />
    </div></Col>
  </Row>
  </Container>
  // <div className="whoweare">
  //   <div className="whoweare-content">
  //     <h1>{title}</h1>
  //     {content.map((paragraph, index) => (
  //       <p key={index}>{paragraph}</p>
  //     ))}
  //   </div>
  //   <div className="whoweare-image">
  //     <img src={imageSrc} alt={title} />
  //   </div>
  // </div>
);

export default WhoWeAre;
